import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { commonStyle } from "../theme/theme";
import GenericGridCard from "../components/GenericGridCard";

const Tournaments = () => {
  const variantTournament = [
    {
      id: 1,
      title: "KnockOut tournaments",
      desc: "Knockout tournaments (also known as Bounty tournaments) pay a cash prize for every player you knock out of the tournament. This cash prize is the 'bounty' on the player and is separate from the tournament's prize pool. This means that players with many knockouts can still take home some money, even if they do not make it to a winning position in the tournament. The last player of the tournament - i.e. the winner - will have his/her bounty credited to him/her as winnings.",
    },
    {
      id: 2,
      title: "Progressive Knockout",
      desc: "The bigger the catch, the better the prize! While regular Knockout pays a player the same fixed amount for each knockout Progressive Knockout places a different price on each player's head. In this variant, you win 50% of the bounty each time you knock out a player and the rest gets added to your bounty. As the tournament progresses, players with many knockouts will subsequently have higher bounties, and will be worth more to knock out! The last player of the tournament - i.e. the winner - will have his/her bounty credited to him/her as winnings.",
    },
    {
      id: 3,
      title: "Win the Button",
      desc: "In a regular poker game, the dealer button is moved around the table in a clockwise manner. However, in this format, the button will be passed to whoever wins the round. Effectively, this gives them the advantage of acting last in the game. Win the Button is an action-packed variant that rewards players that actively go for the win.",
    },
    {
      id: 4,
      title: "Sit & Go Tournament",
      desc: "This is a single-table tournament that normally involves ten players where the last player who survives wins the tournament. Satellites are usually sit-and-go tournaments.",
    },
    {
      id: 5,
      title: "Turbo Tournament",
      desc: `A turbo tournament features a faster-than-typical structure in which blinds and antes increase at a quicker rate than usual and levels are often of shorter duration. Turbo tournaments are especially popular online, and many live tournaments employ rapid, "turbo"-styled structures (especially for low buy-in events).`,
    },
    {
      id: 6,
      title: "Hyper-Turbo Tournament",
      desc: "A Hyper-Turbo Tournament is one in which the Blinds levels increase extremely quickly - even faster than in a turbo tournament. The length of each round (before the blinds / antes) increase is usually 3-4 minutes.",
    },
    {
      id: 7,
      title: "Time-Bomb Tournament",
      desc: "A Time-Bomb tournament is one that runs for a predetermined amount of time. After the time limit is over, the game is paused and the prize money is distributed amongst the participating players in proportion to their chip stacks. Therefore, the objective of this tournament is to increase your chip stack as much as possible in the predetermined amount of time.",
    },
    {
      id: 8,
      title: "Step-Satellite Tournaments",
      desc: "Step-Satellite tournaments are tournaments where players pay a minimal buy-in fee and compete against each other to qualify for the next level of the tournament, series and satellite.",
    },
    {
      id: 9,
      title: "Satellite Tournaments",
      desc: "Satellite tournaments are tournaments where players pay a buy-in fee and compete against each other to qualify for a series or tournament main event / events.",
    },
    {
      id: 10,
      title: "Multi-Flight Tournaments",
      desc: "Multi-Flight Tournaments are phased tournaments with multiple rounds. Each flight (or phase) will be played up to a certain point of the day and the remaining players will qualify for the final phase of the tournament.",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 5,
        backgroundColor: "rgba(255,255,255,0.1)",
        borderRadius: "1rem",
        padding: 2,
      }}
    >
      {/* the stakes */}
      <Box sx={commonStyle}>
        <Typography variant={"text18Head"}>What is a Tournament?</Typography>
        <Typography variant={"text18"}>
          A poker tournament is a game with a fixed format. Everything is
          predetermined — the blinds, buy-in, stack sizes, antes, structure, and
          time limit.
        </Typography>
        <Typography variant={"text18Head"}>Formats of Tournaments:</Typography>
        <Typography variant={"text18Head"}>
          1. Guaranteed Tournaments
        </Typography>
        <Typography variant={"text18"}>
          In poker, a "guaranteed prize pool is when tournament organizers
          guarantee that a tournament will have a minimum prize pool of a
          certain amount.
        </Typography>
        <Typography variant={"text18Head"}>
          2. Free Entry Tournaments
        </Typography>
        <Typography variant={"text18"}>
          A Free Entry tournament is a poker tournament with no entry fee. Some
          freerolls (eg: Depositors FreeRoll) require payment at some point to
          gain entry to the tournament.
        </Typography>
        <Typography variant={"text18Head"}>Variants of Tournaments</Typography>
        <Grid container spacing={2}>
          {variantTournament.map((item) => {
            return (
              <Grid
                item
                lg={4}
                md={6}
                xs={12}
                sm={6}
                key={item.id}
                // padding={1}
              >
                <GenericGridCard title={item.title} desc={item.desc} />
              </Grid>
            );
          })}
        </Grid>
        <Typography variant={"text18Head"}>
          How to Play a Tournament?
        </Typography>
        <Typography variant="text18">• Log onto the Pro-Poker app.</Typography>
        <Typography variant="text18">
          • Choose the tournament of your choice. • Pay the entry fees. • Win
          massive rewards on India's biggest poker platform!
        </Typography>
        <Typography variant="text18">
          • Depending on the type of tournament, you may also choose to qualify
          for the main event by registering to play in a step-satty or satellite
          tournament i.e. the reward for winning a satellite tournament is a
          ticket to the main event.
        </Typography>
        <Typography variant="text18">
          • Minimal spends. Maximum Rewards. Win from massive prize-pools every
          month! • Test your skills against the best and develop your game! • Go
          for Glory! Trophies, events and medals for the country's best players!
        </Typography>
      </Box>
    </Box>
  );
};

export default Tournaments;

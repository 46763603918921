import { Box, Button } from "@mui/material";
import React from "react";
import nft1 from "../assets/images/1.png";
import nft2 from "../assets/images/2.png";
import nft3 from "../assets/images/3.png";
import nft4 from "../assets/images/4.png";
import nft5 from "../assets/images/5.png";
import { useNavigate } from "react-router-dom";

const NFTs = () => {

  const navigate=useNavigate()

  return (
    <Box my={4}>
      <Box
        sx={{
          display: { lg: "flex", xs: "none" },
          gap: 2,
          px: 6,
          justifyContent: "center",
          // overflowX: { md: "auto" },
        }}
      >
        <Box
          height={300}
          mt={12}
          sx={{
            borderRadius: ".8rem",
            overflow: "hidden",
            transform: "rotate(-13deg) scale(0.8, 0.8)",
            transition: ".5s",
            ":hover": {
              transform: "rotate(-13deg) scale(1, 1)",
              boxShadow: "2px 1px 34px 6px #fff",
            },
          }}
        >
          <img src={nft1} alt="" height={"100%"} className="" />
        </Box>
        <Box
          height={300}
          mt={4}
          sx={{
            borderRadius: ".8rem",
            overflow: "hidden",
            transform: "rotate(-13deg) scale(0.8, 0.8)",
            transition: ".5s",
            ":hover": {
              transform: "rotate(-13deg) scale(1, 1)",
              boxShadow: "2px 1px 34px 6px #fff",
            },
          }}
        >
          <img src={nft2} alt="" height={"100%"} />
        </Box>
        <Box
          height={300}
          sx={{
            borderRadius: ".8rem",
            overflow: "hidden",
            transform: " scale(0.8, 0.8)",
            transition: ".5s",
            ":hover": {
              transform: "rotate(0deg) scale(1, 1)",
              boxShadow: "2px 1px 34px 6px #fff",
            },
          }}
        >
          <img src={nft3} alt="" height={"100%"} />
        </Box>
        <Box
          height={300}
          mt={4}
          sx={{
            borderRadius: ".8rem",
            overflow: "hidden",
            transform: "rotate(13deg) scale(0.8, 0.8)",
            transition: ".5s",
            ":hover": {
              transform: "rotate(13deg) scale(1, 1)",
              boxShadow: "2px 1px 34px 6px #fff",
            },
          }}
        >
          <img src={nft4} alt="" height={"100%"} />
        </Box>
        <Box
          height={300}
          mt={12}
          sx={{
            borderRadius: ".8rem",
            overflow: "hidden",
            transform: "rotate(13deg) scale(0.8, 0.8)",
            transition: ".5s",
            ":hover": {
              transform: "rotate(13deg) scale(1, 1)",
              boxShadow: "2px 1px 34px 6px #fff",
            },
          }}
        >
          <img src={nft5} alt="" height={"100%"} />
        </Box>
      </Box>
      <Box
        sx={{
          borderRadius: ".8rem",
          overflow: "hidden",
          display: { xs: "flex", lg: "none" },
          justifyContent: { sm: "center", xs: "center" },
          gap: 3,
          flexWrap: "wrap",
          px: 2,
        }}
      >
        <Box height={280} my={2}>
          <img src={nft1} alt="" height={"100%"} className="hovereffect" />
        </Box>
        <Box height={280} my={2}>
          <img src={nft2} alt="" height={"100%"} className="hovereffect" />
        </Box>
        <Box height={280} my={2}>
          <img src={nft3} alt="" height={"100%"} className="hovereffect" />
        </Box>
        <Box height={280} my={2}>
          <img src={nft4} alt="" height={"100%"} className="hovereffect" />
        </Box>
        <Box height={280} my={2}>
          <img src={nft5} alt="" height={"100%"} className="hovereffect" />
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: 2, px: 6, justifyContent: "center" }}>
        <Box sx={{ borderRadius: "25px", bgcolor: "", padding: "2px" }}>
          <Button
            variant="contained"
            sx={{
              // width: "100%",
              height: "100%",
              bgcolor: "transparent",
              borderRadius: "25px",
              color: "#fff",
              fontSize: "1.5rem",
              textTransform: "capitalize",
              border: "1px solid #fff",
              px: 6,
              py: 1,
              ":hover": {
                bgcolor:
                  "linear-gradient(90deg, rgba(216,82,5,1) 0%, rgba(100,0,178,1) 100%)",
              },
            }}
            onClick={()=>navigate('/play')}
          >
            Mint NFTs
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NFTs;

import { Box, Typography } from "@mui/material";
import React from "react";

const GenericGridCard = ({ title, desc }) => {
  return (
    <Box
      sx={{
        gap: 5,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "rgba(255,255,255,0.1)",
        borderRadius: "1rem",
        padding: "1rem",
        height: "100%",
        gap: "1rem",
      }}
    >
      <Typography variant="text18Head">{title}</Typography>
      <Typography variant="text18" >{desc}</Typography>
    </Box>
  );
};

export default GenericGridCard;

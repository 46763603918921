import React from "react";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import popupBg from "../assets/images/popupBg.png";
import pokerLogo from "../assets/images/header_logo.png";
import crossicon from "../assets/images/crossIcon.png";
import { useNavigate } from "react-router-dom";

const BuyPPTPopup = ({ open, handleClose }) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        outline: "none",
      }}
    >
      <Box
        sx={{
          backgroundImage:
            "linear-gradient(to right, #a62e50, #a80760, #a10076, #8e0092, #6400b2)",
          outline: "none",
          p: ".2rem",
          borderRadius: "1rem",
          mx: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            //   width: "10rem",
            //   height: "10rem",
            outline: "none",
            borderRadius: "1rem",
            backgroundImage: `url(${popupBg})`,
            backgroundSize: "100% 100%",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            px: { sm: "10rem", xs: "5rem" },
            py: { sm: "5rem", xs: "2.5rem" },
            //   background:'rgba(255,255,255,0.1)'
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: 0, top: 0 }}
            onClick={handleClose}
          >
            {/* <CloseRoundedIcon /> */}
            <img src={crossicon} style={{ width: "6rem" }} />
          </IconButton>
          <img src={pokerLogo} style={{ width: "6rem", aspectRatio: "1" }} />
          <Typography
            sx={{
              fontSize: { sm: "3.5rem", xs: "3rem" },
              fontWeight: "600",
              mt: 1,
            }}
          >
            $PPT Token
          </Typography>
          <Typography
            sx={{
              fontSize: { sm: "3.5rem", xs: "2.5rem" },
              fontWeight: "400",
              textAlign: "center",
            }}
          >
            $PPT PRESALE is live !
          </Typography>
          <Box
            sx={{
              background: `linear-gradient(90deg,#d85205 -49.74%,#6400b3 75.63%)`,
              borderRadius: "1rem",

              overflow: "hidden",
              width: "fit-content",
              height: "fit-content",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              mt: 2,
            }}
          >
            <Button
              variant=""
              onClick={() => {
                handleClose();
                // navigate("/play");
                window.open("https://www.pinksale.finance/launchpad/bsc/0xd65A09b74a951fDB6711FaAaaFAcd84B4E8cD16d", "_blank");
              }}
              sx={{
                //   backgroundColor: "#4B1307",
                //   borderRadius: "10rem",
                border: "none",
                outline: "none",
                color: "white",
                textTransform: "none",
                px: "3rem",
                // WebkitTextStroke:'.1rem black'
                // ,fontWeight:'700'
              }}
            >
              BUY NOW !
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default BuyPPTPopup;

import { Grid } from "@mui/material";
import React from "react";
import HandsCard from "../components/HandsCard";
import royalFlush from "../assets/images/royal-flush 1.png";
import straightFlush from "../assets/images/straight-flush 1.png";
import fourKind from "../assets/images/quads 1.png";
import fullHouse from "../assets/images/full-house 1.png";
import flush from "../assets/images/flush 1.png";
import straight from "../assets/images/straight 1.png";
import three from "../assets/images/three-of-a-kind 1.png";
import twoPair from "../assets/images/two-pair 1.png";
import onePair from "../assets/images/one-pair 1.png";
import hightCard from "../assets/images/high-card 1.png";


const handsData = [
  {
    id: 1,
    title: "Royal Flush",
    image: royalFlush,
    desc: "Royal Flush is the best possible hand combination consisting of Ace, King, Queen, Jack and 10 of the same suit. Nothing can beat this five-card hand combination in the game of Poker.",
  },
  {
    id: 2,
    title: "Straight Flush",
    image: straightFlush,
    desc: "Straight Flush consists of 5 cards which are in a consecutive rank, for e.g., 6,7,8, 9 & 10 of Hearts. If two players hit straight flush, then the one with the higher denomination wins the game.",
  },
  {
    id: 3,
    title: "Four Of A Kind",
    image: fourKind,
    desc: "Four of A Kind consists of 4 cards of the same rank, such as four aces or four eights, and higher value cards beat the lower value cards.",
  },
  {
    id: 4,
    title: "Full House",
    image: fullHouse,
    desc: "Full House is the hand combination with three cards of the same rank and two other cards of a different rank.",
  },
  {
    id: 5,
    title: "Flush",
    image: flush,
    desc: "Flush is when a player has 5 different cards (mix of high and low cards) of the same suit.",
  },
  {
    id: 6,
    title: "Straight",
    image: straight,
    desc: "Straight is formed when you have five cards in a sequential order..",
  },
  {
    id: 7,
    title: "Three Of A Kind",
    image: three,
    desc: "Three of A Kind applies when a player makes three cards of the same rank and two cards of a different suit",
  },
  {
    id: 8,
    title: "Two Pair",
    image: twoPair,
    desc: "Two Pair is formed with two pairs of cards of the same value.",
  },
  {
    id: 9,
    title: "Pair",
    image: onePair,
    desc: "A Pair is formed with one pair of cards of the same value.",
  },
  {
    id: 10,
    title: "High Card",
    image: hightCard,
    desc: "Any combination that does  not fall into any of the  categories mentioned above.",
  },
];

const Hands = () => {
  return (
    <Grid
      container
      sx={{
        backgroundColor: "rgba(255,255,255,0.1)",
        borderRadius: "1rem",
        padding: 1,
      }}
    >
      {handsData  .map((item) => {
        return (
          <Grid item lg={3} md={4} xs={12} sm={6}  key={item.id} padding={1}>
            <HandsCard
              title={item.title}
              image={item.image}
              desc={item.desc}
              index={item.id}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Hands;

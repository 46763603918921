import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import OutletComponent from "../components/OutletComponent";
import Technology from "./Technology";
import Roadmap from "./Roadmap";
import NFTs from "./NFTs";
import AboutUs from "./AboutUs";
import HowToPlayOutlet from "../components/HowToPlayOutlet";
import GettingStarted from "./GettingStarted";
import Videos from "./Videos";
import Hands from "./Hands";
import Actions from "./Actions";
import Tournaments from "./Tournaments";
import Home from "./Home";
import ComingSoon from "./ComingSoon";
import { useState } from "react";
import BuyPPTPopup from "../components/BuyPPTPopup";

const PageRoutes = () => {
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Router>
      <BuyPPTPopup
       open={open} handleClose={handleClose} />

      <Routes>
        <Route element={<OutletComponent />}>
          <Route path="/" element={<Home />} />
          <Route path="/howToPlay" element={<HowToPlayOutlet />}>
            <Route path={"/howToPlay"} element={<Navigate to={"gettingStarted"} />} />
            <Route path={"videos"} element={<Videos />} />
            <Route path={"gettingStarted"} element={<GettingStarted />} />
            <Route path={"hands"} element={<Hands />} />
            <Route path={"actions"} element={<Actions />} />
            <Route path={"tournaments"} element={<Tournaments />} />
          </Route>
          <Route path="/technology" element={<Technology />} />
          <Route path="/roadmap" element={<Roadmap />} />
          <Route path="/NFTs" element={<NFTs />} />
          <Route path="/aboutUs" element={<AboutUs />} />
        </Route>
        <Route path="/play" element={<ComingSoon />} />
      </Routes>
    </Router>
  );
};

export default PageRoutes;

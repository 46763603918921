import React from "react";

import logo from "../assets/images/header_logo.png";

import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, NavLink, useNavigate } from "react-router-dom";
import routes from "../routes/routes";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import BuyPPTPopup from "./BuyPPTPopup";

const Header = (props) => {
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", height: "100%", background: "#140121" }}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        height={"4rem"}
        my={2}
        sx={{ cursor: "pointer" }}
        onClick={() =>
           navigate("/")
          }
      >
        <Typography variant="h6" px={2}>
          PRO-POKER.XYZ
        </Typography>
        <Box
          sx={{
            position: "absolute",
            right: 17,
            // top: 17,
            cursor: "pointer",
            fontSize: "20px",
          }}
          // onClick={handleClose}
        >
          {/* <img src={closeIcon} alt="" height={40} /> */}X
        </Box>
      </Box>
      <Box>
        {navItems.map((item) => (
          <Box my={4} ml={2}>
            <NavLink to={item.path} style={{ textDecoration: "none" }}>
              <Typography
                sx={{
                  textDecoration: "none",
                  textAlign: "start",
                  color: "#cbc3c3e3",
                  ":hover": {
                    color: "#fff",
                  },
                }}
              >
                {item.name}
              </Typography>
            </NavLink>
          </Box>
        ))}
        <Box
          mx={2}
          height={"100%"}
          // width={{ md: "100%", xs: 110 }}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Link to="https://www.pinksale.finance/launchpad/bsc/0xd65A09b74a951fDB6711FaAaaFAcd84B4E8cD16d" target="_blank" style={{ textDecoration: 'none', width: "100%"}}>
          <Button
          
            variant="contained"
            sx={{
              width: "100%",
              height: "100%",
              bgcolor: "transparent",
              borderRadius: "25px",
              color: "#fff",
              fontSize: "1.25rem",
              textTransform: "capitalize",
              border: "1px solid #fff",
              px: 3,
              py: 1,
              ":hover": {
                bgcolor:
                  "linear-gradient(90deg, rgba(216,82,5,1) 0%, rgba(100,0,178,1) 100%)",
              },
            }}
          >
            Buy $PPT token 

          </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div>
      <AppBar
        component="nav"
        position="static"
        sx={{
          // bgcolor: "#fff",
          color: "#2D2C2C",
          justifyContent: "center",
          boxShadow: "none",
          padding: { xs: "10px", lg: "10px 40px" },
          bgcolor: "#13012352",
          backdropFilter: "blur(15px)",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingX: { xs: 0, sm: 1 },
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              // minWidth: { md: "30%", lg: "30%" },
              width: { xs: "100%", md: "unset" },
              justifyContent: "space-between",
            }}
          >
            <IconButton
              size="large"
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: { sm: 2 }, display: { md: "none" }, color: "black" }}
            >
              <MenuIcon fontSize="50px" />
            </IconButton>
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={2}
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              <Box
                sx={{
                  display: { sm: "flex" },
                  width: { xs: 35, sm: 40, md: 50, lg: 60 },
                }}
              >
                <img src={logo} alt="" width={"100%"} />
              </Box>
              <Typography variant="h2" sx={{ fontSize: "2rem", width: "100%" }}>
                PRO-POKER.XYZ
              </Typography>
            </Box>
          </Box>
          <Box
            display={{ md: "flex", xs: "none" }}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"70%"}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              width={{ md: "80%", xs: "100%" }}
            >
              {navItems.map((item) => (
                <NavLink to={item.path} style={{ textDecoration: "none" }}>
                  {({ isActive, isPending, isTransitioning }) => (
                    <Box sx={{ position: "relative" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          color: isActive ? "" : "#cbc3c3e3",
                          ":hover": {
                            color: "#fff",
                          },
                        }}
                      >
                        {item.name}
                      </Typography>
                      {isActive && (
                        <FiberManualRecordIcon
                          sx={{
                            position: "absolute",
                            width: "1rem",
                            bottom: "-1.5rem",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, 15%)",
                          }}
                        />
                      )}
                    </Box>
                  )}
                </NavLink>
              ))}
            </Box>
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                height: "100%",
                alignItems: "center",
                // minWidth: '20%',
              }}
            >
              <Box
                mx={2}
                height={"100%"}
                width={{ md: "100%", xs: 110 }}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                 <Link to="https://www.pinksale.finance/launchpad/bsc/0xd65A09b74a951fDB6711FaAaaFAcd84B4E8cD16d" target="_blank" style={{ textDecoration: 'none' }}>
                <Button
                  // onClick={()=>navigate('/play')}
                  // onClick={()=> handleClick() }
                  variant="contained"
                  sx={{
                    width: "100%",
                    height: "100%",
                    bgcolor: "transparent",
                    borderRadius: "25px",
                    color: "#fff",
                    fontSize: "1.25rem",
                    textTransform: "capitalize",
                    border: "1px solid #fff",
                    px: 2,
                    py: 1,
                    whiteSpace:'nowrap',
                    transition: ".2s",
                    ":hover": {
                      background:
                        "linear-gradient(90deg, rgba(216,82,5,1) 0%, rgba(100,0,178,1) 100%)",
                      border: "none",
                      boxShadow: '-1px -1px 400px 42px rgba(255,255,255,0.2)'

                    },
                  }}
                >
                 Buy $PPT token 

                </Button>
                </Link>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: { xs: "100%", sm: 280 },
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </div>
  );
};

export default Header;

const navItems = [
  {
    name: "How to Play",
    path: "/howToPlay/",
  },
  {
    name: "Technology",
    path: "/technology",
  },
  {
    name: "Roadmap",
    path: "/roadmap",
  },
  {
    name: "NFTs",
    path: "/nfts",
  },
  {
    name: "About us",
    path: "/aboutUs",
  },
];

import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import React from "react";
import lineImg from "../assets/images/borderImage.png";
import cardBg from "../assets/images/cardsImage.png";
import { db } from "../firebase";
import { collection, addDoc, doc, setDoc } from "firebase/firestore";
import toast, { Toaster } from "react-hot-toast";

const Home = () => {
  const [email, setEmail] = useState("");

  const submitEmail = async () => {
    if (!email.length) return;
    console.log(email);

    const loadingToast = toast.loading("Submitting response");
    try {
      const docRef = doc(db, "propoker", `${email}_${getRandomNumber()}`);
      await setDoc(docRef, { email: email, createdAt: new Date() });
      console.log("Document written with ID: ", docRef.id);
      setEmail("");
      toast.success("Your response was submitted", { id: loadingToast });
    } catch (error) {
      toast.error("Something went wrong", { id: loadingToast });
    }
  };

  const getRandomNumber = () => {
    return Math.floor(Math.random() * 100000);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        paddingX: { lg: "15rem", xs: "2rem" },
      }}
    >
      <Box
        sx={{
          width: "100%",
          paddingY: { sm: "13rem", xs: "5rem" },
          background: `url(${lineImg})`,
          backgroundSize: "100% 100%",
          paddingX: { sm: "8rem", xs: "2rem" },
          display: "flex",
          flexDirection: { sm: "row", xs: "column-reverse" },
          alignItems: "center",
          position: "relative",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { sm: "3rem", md: "5rem", xs: "2.5rem" },
              WebkitTextStroke: ".1rem black",
            }}
          >
            Experience the{" "}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { sm: "3rem", md: "5rem", xs: "2.5rem" },
              WebkitTextStroke: ".1rem black",
            }}
          >
            Thrill of Pro Poker
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { md: "row", xs: "column" },
              mt: { xs: 1 },
              alignItems: "center",
            }}
          >
            {/* <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="home-input"
              placeholder="Enter Your Email"
              type="email"
            /> */}
            <Box
              sx={{
                background: `linear-gradient(90deg,#d85205 -49.74%,#6400b3 75.63%)`,
                borderRadius: "10rem",
                p: ".1rem",
                overflow: "hidden",
                width: "fit-content",
                height: "fit-content",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Button
                variant="outlined"
                // onClick={submitEmail}
                onClick={(e) => {
                  window.open('https://drive.google.com/file/d/1jHVci6tCq0sTWPWxJnOCn0A9SKDas3rx/view?usp=sharing', '_blank');
                }}
                sx={{
                  backgroundColor: "#4B1307",
                  borderRadius: "10rem",
                  color: "white",
                  textTransform: "none",
                  // WebkitTextStroke:'.1rem black'
                  // ,fontWeight:'700'
                }}
              >
                Whitepaper
              </Button>
            </Box>
            <Box
              sx={{
                background: `linear-gradient(90deg,#d85205 -49.74%,#6400b3 75.63%)`,
                borderRadius: "10rem",
                p: ".1rem",
                overflow: "hidden",
                width: "fit-content",
                height: "fit-content",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Button
                variant="outlined"
                // onClick={submitEmail}
                onClick={(e) => {
                  window.open('https://drive.google.com/file/d/1gCv2OqlS9gFpxWQHpY9b6VtjBifPVlBJ/view?usp=sharing', '_blank');
                }}
                sx={{
                  backgroundColor: "#4B1307",
                  borderRadius: "10rem",
                  color: "white",
                  textTransform: "none",
                  // WebkitTextStroke:'.1rem black'
                  // ,fontWeight:'700'
                }}
              >
                Tokenomics
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            marginLeft: { md: 5, xs: 0 },
          }}
        >
          <img src={cardBg} className="cardBg" />
        </Box>
      </Box>
    </Box>
  );
};

export default Home;

import { Box, Typography } from "@mui/material";
import React from "react";
import bgVideo from "../assets/Pro Poker Background Regular.mp4";
import bgImage from "../assets/images/header_logo.png";

const ComingSoon = () => {
  return (
    <Box
      sx={{
        maxHeight: "100vh",
        minWidth: "100vw",
        minHeight: "100vh",
        maxWidth: "100vw",
        overflow: "hidden",
      }}
    >
      <video
        src={bgVideo}
        autoPlay={true}
        loop={true}
        muted
        controls={false}
        style={{ width: "100vw", height: "100vh", objectFit: "cover" }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: 2,
          minWidth:'95vw'
        }}
      >
        <img src={bgImage} className="center-logo" />
        <Typography
          sx={{
            fontSize: { sm: "4rem", xs: "2rem" },
            textShadow:
              " 0px 0px 472.93px #C3A7CA, 0px 0px 270.246px #C3A7CA, 0px 0px 157.643px #C3A7CA, 0px 0px 78.8216px #C3A7CA, 0px 0px 22.5205px #C3A7CA, 0px 0px 11.2602px #C3A7CA",
          }}
        >
          PRO POKER.xyz
        </Typography>
        <Typography
          sx={{
            fontSize: { sm: "7rem", xs: "3rem" },
            textShadow:
              " 0px 0px 472.93px #C3A7CA, 0px 0px 270.246px #C3A7CA, 0px 0px 157.643px #C3A7CA, 0px 0px 78.8216px #C3A7CA, 0px 0px 22.5205px #C3A7CA, 0px 0px 11.2602px #C3A7CA",
          }}
        >
          Coming Soon . . .
        </Typography>
      </Box>
    </Box>
  );
};

export default ComingSoon;

import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import polygon from "../assets/images/polygon.svg";
import express from "../assets/images/express.svg";
import mongo from "../assets/images/mongo.svg";
import solidity from "../assets/images/solidity.svg";

const Technology = () => {
  return (
    <Box sx={{ minHeight: "80vh" }}>
      <Box
        height={"100%"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid container height={"80%"} width={{md:"60%",sm:'90%'}}>
          {technologyData.map((dt) => (
            <Grid item xs={12} sm={6} p={2}>
              <Box
                sx={{
                  bgcolor: "#ffffff1f",
                  p: 4,
                  display: "flex",
                  gap: 2,
                  flexDirection: "column",
                  minHeight: "220px",
                  borderRadius:2,
                  height:'100%'
                }}
              >
                <Box sx={{ width: 40, height: 40 }}>
                  <img
                    src={dt.image}
                    alt=""
                    srcset=""
                    width={"100%"}
                    height={"100%"}
                  />
                </Box>
                <Typography sx={{ fontSize: "1.45rem" }}>
                  {dt.heading}
                </Typography>
                <Typography sx={{ fontSize: "1.2rem", color: "#cbc3c3e3",width:{md:'90%'} }}>
                  {dt.desc}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Technology;

const technologyData = [
  {
    image: polygon,
    heading: "Polygon",
    desc: "The decentralized poker game will be built on the Polygon blockchain, leveraging its smart contract capabilities for secure and transparent gameplay and transaction handling",
  },
  {
    image: solidity,
    heading: "Solidity",
    desc: "The primary programming language for developing smart contracts on the Polygon blockchain. Solidity will be used to implement the game logic, token management, and interaction with the blockchain.",
  },
  {
    image: express,
    heading: "Express.js",
    desc: "A lightweight and flexible framework for building web applications with Node.js.",
  },
  {
    image: mongo,
    heading: "MongoDB",
    desc: "A NoSQL database that offers flexibility and high performance for handling unstructured data.",
  },
];

import {initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBKqUfdeRGEn2U-dxWJrxmTDIpxs1WfoSs",
  authDomain: "d-webbox.firebaseapp.com",
  databaseURL:
    "https://d-webbox-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "d-webbox",
  storageBucket: "d-webbox.appspot.com",
  messagingSenderId: "875499189831",
  appId: "1:875499189831:web:cddbaf991b456f56e72362",
  measurementId: "G-XNXQ86LND7",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// export const firestore = firebase.firestore();
export const db = getFirestore(app);

import { Box } from '@mui/material'
import React from 'react'
// import roadmap from '.././assets/images/Roadmap.png'
import roadmap from '.././assets/images/roadmap_v2.png'

const Roadmap = () => {
  return (
    <Box mt={4}>
      <Box display={'flex'} justifyContent={'center'}>
      <Box height={{sm:'auto',xs:'auto'}} width={{md:'40vw',sm:'60vw',xs:'90vw'}}>
        <img src={roadmap} alt="" height={'100%'} width={'100%'}/>
      </Box>
      </Box>
    </Box>
  )
}

export default Roadmap
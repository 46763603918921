import React from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import { Box } from "@mui/material";

const OutletComponent = () => {
  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Box sx={{position:'sticky', top:0 , zIndex:10 }}>
        <Header />
      </Box>
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column" , my:5  }}>
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};

export default OutletComponent;

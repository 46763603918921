import React from "react";
import HowToPlayHeader from "./HowToPlayHeader";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const HowToPlayOutlet = () => {
  return (
    <>
      <HowToPlayHeader />
      <Box
        sx={{
          paddingX: {
            md: "10rem",
            xs: "2rem",
          },
        }}
      >
        <Outlet />
      </Box>
    </>
  );
};

export default HowToPlayOutlet;

import React from "react";
import PageRoutes from "./routes/routes";
import { ThemeProvider } from "@mui/material/styles";
import muiTheme from "./theme/theme";
import { Toaster } from "react-hot-toast";
const App = () => {
  return (
    <ThemeProvider theme={muiTheme}>
      <PageRoutes />
      <Toaster
        // containerStyle={{
        //   top:80
        // }}
        toastOptions={{
          className: "",
          duration: 5000,
          style: {
            fontFamily: "Poppins",
            fontSize: "1.5rem",
            fontWeight: "500",
            color: "#FFF",
            background: "linear-gradient(90deg,#d85205 -49.74%,#6400b3 75.63%)",
          },
        }}
      />
    </ThemeProvider>
  );
};

export default App;

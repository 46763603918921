import { createTheme } from "@mui/material";

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#130123",
    },
  },
  typography: {
    // Tell Material UI what the font-size on the html element is.
    htmlFontSize: 10,
    fontFamily: "Poppins",
    allVariants: {
      color: "#FFFFFF",
      fontFamily: "Poppins",
      fontWeight: "500",
    },
    textInput: {
      marginBottom: "0.4rem",
      fontWeight: "600",
      fontSize: "1.6rem",
      color: "#FFFFFF",
    },
    textSecondary: {
      color: "#AA9FB4",
    },
    textTertiary: {
      color: "#B1B1B1",
    },
    text18:{
      color: "#B1B1B1",
      fontSize:'1.5rem'
    },
    text18Head:{
      fontSize:'1.5rem',
      fontWeight:'500'
    },
    text24Head:{
      fontSize:'1.8rem',
      fontWeight:'500'
    }
  },
  //   breakpoints: {
  //     values: {
  //       xs: 0,
  //       sm: 600,
  //       md: 640,
  //       lg: 1025,
  //       xl: 1536,
  //     },
  //   },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
  },
});

export default muiTheme;


export const commonStyle = {
  display: "flex",
  flexDirection: "column",
  gap: { xs: 1.5, md: 1.5 },
};

export const glassBoxStyles = {
  backgroundColor: "rgba(255,255,255,0.1)",
  borderRadius: "1rem",
  padding: 1,
  display: "flex",
  flexDirection: "column",
  flex: 1,
  gap: { xs: 1.5, md: 1.5 },

};
import React from "react";
import { commonStyle, glassBoxStyles } from "../theme/theme";
import { Box, Grid, Typography } from "@mui/material";
import GenericGridCard from "../components/GenericGridCard";

const actionsData = [
  {
    id: 1,
    title: "Fold",
    desc: 'Players may forego the action thinking that the cards they are dealt with are not good enough. Should they do so, they forfeit their cards by "Folding." This also puts them out of competition for that very hand.',
  },
  {
    id: 2,
    title: "Wager",
    desc: "A Wager is a token amount that acts as a minimum for other players to match in order to remain in the game.",
  },
  {
    id: 3,
    title: "Call",
    desc: "A Call means that a player places an amount equal to the last-placed wager on the table.",
  },
  {
    id: 4,
    title: "Check",
    desc: "When a player Checks, he is consciously declining the option to open the pot from his side and is rather transferring the action to the person sitting to his left. One can Check only when there is no wager in progress at that very instance",
  },
  {
    id: 5,
    title: "Raise",
    desc: "A raise happens when a player increases the amount of the last-placed wager on the table. A player may do it when he has a better card combination or when he is bluffing.",
  },
];

const Actions = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 5,
        backgroundColor: "rgba(255,255,255,0.1)",
        borderRadius: "1rem",
        padding: 2,
      }}
    >
      {/* the stakes */}
      <Box sx={commonStyle}>
        <Typography variant={"text24Head"}>The Stakes</Typography>
        <Typography variant={"text18"}>
          Poker involves placing forced amounts, and they are called Big Blind
          and Small Blind respectively. These forced amounts start the formation
          of "Pot", the minimum amount of money that works as an incentive for
          players to continue and win the game. As the game progresses, the Pot
          size (or the money on the table) also increases.
        </Typography>
        <Typography variant={"text18"}>
          Both Texas Hold'em and Pot-Limit Omaha feature four rounds each. These
          four rounds are called Pre-Flop, Flop, Turn and River. The cards are
          dealt one by one to each player. The distribution of the cards starts
          from the player sitting to the immediate left of the Dealer and then
          proceeds in a clockwise manner around the table. The next player to
          receive the cards is the Big Blind. It is important to understand that
          the Small Blind starts the game by placing an amount that is usually
          half that of the blind posted by the Big Blind.
        </Typography>
        <Typography variant={"text18"}>
          Five interesting scenarios may arise and a player may take one of them
          at a time with regards to his game. These are:
        </Typography>
        <Grid container spacing={2} >
          {actionsData.map((item) => {
            return (
              <Grid
                item
                lg={2.4}
                md={3}
                xs={12}
                sm={6}
                key={item.id}
                // padding={1}
              >
                <GenericGridCard title={item.title} desc={item.desc} />
              </Grid>
            );
          })}
        </Grid>
        <Typography variant={"text18"}>
          As explained previously, players may fall out of the race at every
          instance of raising during Pre-Flop, Flop, Turn and River rounds. This
          is because one may think one's cards are not worth matching or
          increasing the stakes, and this is where decision-making skills become
          so crucial. So players need to continue placing chips in the pot at
          every such round until every active player has either matched the
          raise made or folded his cards. This process repeats until the hand is
          complete. Showdown
        </Typography>
      </Box>
      <Box sx={commonStyle}>
        <Typography variant={"text24Head"}>Showdown</Typography>
        <Typography variant={"text18"}>
          As the name suggests, the Showdown phase is the last phase in a game.
          It means all the active and competing players now show their cards and
          the player with the best five-card combination is the winner. Please
          note that this particular phase starts after the placing of the last
          Raise in the final round called River.
        </Typography>
      </Box>
      {/* all in */}
      <Box sx={commonStyle}>
        <Typography variant={"text24Head"}>All In</Typography>
        <Typography variant={"text18"}>
          All-In is a unique scenario in which all the chips of a particular
          player are in the pot before the completion of the hand. All active
          players who don't have enough chips to compete further in a game, are
          rendered All-In if they wish to continue in that hand. If one of them
          wins, that player gets an amount proportional to his All-In wager. The
          game continues and other competitive players are allotted a 'Side Pot'
          to play.
        </Typography>
      </Box>
    </Box>
  );
};

export default Actions;

import { Box, Typography } from "@mui/material";
import React from "react";
import { commonStyle, glassBoxStyles } from "../theme/theme";

const GettingStarted = () => {
 

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 5,
        backgroundColor: "rgba(255,255,255,0.1)",
        borderRadius: "1rem",
        padding: 2,
      }}
    >
      {/* getting stated */}
      <Box sx={commonStyle}>
        <Typography variant={"text18Head"}>Getting Stared</Typography>
        <Typography variant={"text18"}>
          Poker is a popular strategy card game that tests your mathematical,
          psychological, decision making and strategy skills. Every hand unfolds
          new gameplay and the pressure to make the right reads and decisions is
          always on you. Cards carry a value based on the patterns they form and
          it's the players endeavour to make the best possible combination.
        </Typography>
      </Box>
      {/* Hole cards */}
      <Box sx={commonStyle}>
        <Typography variant={"text18Head"}>
          Hole Cards and Community Cards
        </Typography>
        <Typography variant={"text18"}>
          a. Hole Cards: Any two random cards that are dealt face down to a
          player.
        </Typography>
        <Typography variant={"text18"}>
          b. Community Cards: The five cards that are placed face-up in the
          middle of a poker.{" "}
        </Typography>
        <Typography variant={"text18"}>
          All players in the hand are allowed to use any of these five cards to
          make the best possible combination. The objective is to use your cards
          hole cards with any of the community cards to form your winning hand.
        </Typography>
      </Box>
      {/* variants of poker */}
      <Box sx={commonStyle}>
        <Typography variant={"text18Head"}>Variant of Poker </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { sm: "row", xs: "column" },
            gap: 2,
          }}
        >
          <Box sx={glassBoxStyles}>
            <Typography variant={"text18Head"}>
              1. No-Limit Texas Hold'em(NLHE):
            </Typography>
            <Typography variant={"text18"}>
              Texas hold 'em is one of the most popular variants of poker. Two
              cards, known as hole cards, are dealt face down to each player,
              and then five community cards are dealt face up in three stages.
            </Typography>
            <Typography variant={"text18"}>
              In Texas Hold'em, a player must make the best five-card poker hand
              using his two Hole Cards and five Community Cards. The player with
              the best card combination is the winner.
            </Typography>
          </Box>
          <Box sx={glassBoxStyles}>
            <Typography variant={"text18Head"}>
              2. Pot Limit Omaha(PLO):
            </Typography>
            <Typography variant={"text18"}>
              Omaha Hold'em is a community card poker game similar to Texas hold
              'em, where each player is dealt four/five cards and must make his
              or her best hand using exactly two of them, plus exactly three of
              the five community cards.
            </Typography>
            <Typography variant={"text18"}>
              In Pot-Limit Omaha, a player is dealt four hole cards of which he
              must use two. Any of the two hole cards in combination with any of
              the five community cards will form the winning hand
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* element of online poker */}
      <Box sx={commonStyle}>
        <Typography variant={"text18Head"}>Elements of Online Poker</Typography>
        <Typography variant={"text18"}>
          The physical objects needed to play a game of poker are a deck of
          cards, a dealer button and chips. They can be found in any poker chip
          set. The rules of the game may vary according to your preference -
          from the number of players, the betting limit to the value of blinds,
          you can choose to play in the format and variant that appeals to you
          the most. Let's elaborate.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { sm: "row", xs: "column" },
            gap: 2,
          }}
        >
          <Box sx={glassBoxStyles}>
            <Typography variant={"text18Head"}>Players</Typography>
            <Typography variant={"text18"}>
              The number of players at a Poker table can be anywhere between 2
              to 9.
            </Typography>
          </Box>
          <Box sx={glassBoxStyles}>
            <Typography variant={"text18Head"}>Chips</Typography>
            <Typography variant={"text18"}>
              Chips are the currency used to make bets in poker. Each colour of
              a chip stands for a different value. To bet, they'll need to put
              the amount of chips they want to bet in the pool of all bets,
              called a pot. That's what they play for, because the winner of the
              hand gets to keep the entire pot.
            </Typography>
          </Box>
          <Box sx={glassBoxStyles}>
            <Typography variant={"text18Head"}>
              The Dealer Button & The Blind Positions
            </Typography>
            <Typography variant={"text18"}>
              The Dealer Button, signifies the position where the last hole card
              is dealt and last turn is played, making it the most advantageous
              position. After every hand, it moves one position to the left, so
              that everyone gets an advantage.
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* betting limits */}
      <Box sx={glassBoxStyles}>
        <Typography variant={"text18Head"}>
          Betting Limits, Hole Cards & Community Cards
        </Typography>
        <Typography variant={"text18"}>
          The maximum number of chips that can be added to the pot by any one
          player in a single bet is called the betting limit. Texas Hold'em, the
          most popular variant of Poker, is most widely played as No Limit Texas
          Hold'em, that is, without a betting limit
        </Typography>
        <Typography variant={"text18"}>
          The game starts when all players get cards distributed to them, face
          down. These cards are called the Hole Cards and are distributed at the
          beginning of each hand. In Texas Holdem, each player is dealt two hole
          cards. In Pot Limit Omaha, each player is dealt 4/5 cards depending on
          the variant.
        </Typography>
        <Typography variant={"text18"}>
          As the game goes on, five community cards will be opened on the table
          to be shared by all players to form their strongest hand.
        </Typography>
      </Box>
    </Box>
  );
};

export default GettingStarted;

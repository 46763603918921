import { Box, Typography } from "@mui/material";
import React from "react";
import handRanking from "../assets/images/Hand rankings.png";
import openFace from "../assets/images/openFace.png";
import potLimit from "../assets/images/potLimit.png";
import texasBg from "../assets/images/texasBg.png";

const Videos = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
      {/* box 1 */}
      <Box
        sx={{
          backgroundColor: "rgba(255,255,255,0.1)",
          borderRadius: "1rem",
          padding: 2,
          justifyContent: "space-between",
          flexDirection: { sm: "row", xs: "column" },
          display: "flex",
          gap: 4,
        }}
      >
        <Box sx={{ width: { sm: "45%" } }}>
          <img src={handRanking} style={{ width: "100%", height: "100%" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            paddingTop: { md: "3rem" },
            gap: "1.5rem",
          }}
        >
          <Typography sx={{ fontSize: { md: "2rem" } }}>About Poker</Typography>
          <Typography sx={{ fontSize: { md: "1.3rem", color: "#AA9FB4" } }}>
            In Texas Hold'em, a player must make the best five-card poker hand
            using his two Hole Cards and five Community Cards. The player with
            the best card combination is the winner.
          </Typography>
          <Typography sx={{ fontSize: { md: "1.3rem", color: "#AA9FB4" } }}>
            In Texas Hold'em, a player must make the best five-card poker hand
            using his two Hole Cards and five Community Cards. The player with
            the best card combination is the winner.
          </Typography>
        </Box>
      </Box>
      {/* box2 */}
      <Box
        sx={{
          backgroundColor: "rgba(255,255,255,0.1)",
          borderRadius: "1rem",
          padding: 2,
          justifyContent: "space-between",
          flexDirection: { sm: "row-reverse", xs: "column" },
          display: "flex",
          gap: 4,
        }}
      >
        <Box sx={{ width: { sm: "45%" } }}>
          <img src={texasBg} style={{ width: "100%", height: "100%" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            paddingTop: { md: "3rem" },
            gap: "1.5rem",
          }}
        >
          <Typography sx={{ fontSize: { md: "2rem" } }}>About Poker</Typography>
          <Typography sx={{ fontSize: { md: "1.3rem", color: "#AA9FB4" } }}>
            In Texas Hold'em, a player must make the best five-card poker hand
            using his two Hole Cards and five Community Cards. The player with
            the best card combination is the winner.
          </Typography>
          <Typography sx={{ fontSize: { md: "1.3rem", color: "#AA9FB4" } }}>
            In Texas Hold'em, a player must make the best five-card poker hand
            using his two Hole Cards and five Community Cards. The player with
            the best card combination is the winner.
          </Typography>
        </Box>
      </Box>
      {/* box3 */}
      <Box
        sx={{
          backgroundColor: "rgba(255,255,255,0.1)",
          borderRadius: "1rem",
          padding: 2,
          justifyContent: "space-between",
          flexDirection: { sm: "row", xs: "column" },
          display: "flex",
          gap: 4,
        }}
      >
        <Box sx={{ width: { sm: "45%" } }}>
          <img src={potLimit} style={{ width: "100%", height: "100%" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            paddingTop: { md: "3rem" },
            gap: "1.5rem",
          }}
        >
          <Typography sx={{ fontSize: { md: "2rem" } }}>About Poker</Typography>
          <Typography sx={{ fontSize: { md: "1.3rem", color: "#AA9FB4" } }}>
            In Texas Hold'em, a player must make the best five-card poker hand
            using his two Hole Cards and five Community Cards. The player with
            the best card combination is the winner.
          </Typography>
          <Typography sx={{ fontSize: { md: "1.3rem", color: "#AA9FB4" } }}>
            In Texas Hold'em, a player must make the best five-card poker hand
            using his two Hole Cards and five Community Cards. The player with
            the best card combination is the winner.
          </Typography>
        </Box>
      </Box>
      {/* box4 */}
      <Box
        sx={{
          backgroundColor: "rgba(255,255,255,0.1)",
          borderRadius: "1rem",
          padding: 2,
          justifyContent: "space-between",
          flexDirection: { sm: "row-reverse", xs: "column" },
          display: "flex",
          gap: 4,
        }}
      >
        <Box sx={{ width: { sm: "45%" } }}>
          <img src={openFace} style={{ width: "100%", height: "100%" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            paddingTop: { md: "3rem" },
            gap: "1.5rem",
          }}
        >
          <Typography sx={{ fontSize: { md: "2rem" } }}>About Poker</Typography>
          <Typography sx={{ fontSize: { md: "1.3rem", color: "#AA9FB4" } }}>
            In Texas Hold'em, a player must make the best five-card poker hand
            using his two Hole Cards and five Community Cards. The player with
            the best card combination is the winner.
          </Typography>
          <Typography sx={{ fontSize: { md: "1.3rem", color: "#AA9FB4" } }}>
            In Texas Hold'em, a player must make the best five-card poker hand
            using his two Hole Cards and five Community Cards. The player with
            the best card combination is the winner.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Videos;

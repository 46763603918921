import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import logo from "../assets/images/header_logo.png";
import twitter from "../assets/images/twitter.svg";
import insta from "../assets/images/insta.svg";
import telegram from "../assets/images/telegram.svg"
import meta from "../assets/images/meta.svg";
import { Link } from "react-router-dom";


import pinksale from "../assets/images/pinksale.png";
import dexview from "../assets/images/dexview.png";

const Footer = () => {
  return (
    <Box
      sx={{
        mt: 2,
        padding: { xs: "10px", lg: "40px 40px" },
        p: 4,
        bgcolor: "#13012352",
        backdropFilter: "blur(5px)",
      }}
    >
      <Grid container spacing={{ xs: 3, sm: 0 }}>
        <Grid item md={3} sm={6} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 3,
            }}
          >
            <Box sx={{ width: 50 }}>
              <img src={logo} width={"100%"} alt="" />
            </Box>
            <Typography variant="h2" sx={{ fontSize: "2rem" }}>
              PRO-POKER.XYZ
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box>
            <Typography variant="h2" sx={{ fontSize: "1.5rem" }}>
              Important Links
            </Typography>
            <Grid container mt={3}>
              {footerLinks.map((link) => (
                <Grid item sm={4} my={1} xs={4} gap={1}>
                  <Link to={link.path} style={{ textDecoration: "none" }}>
                    {" "}
                    <Typography
                      sx={{ fontSize: "1.35rem", color: "#cbc3c3e3" }}
                    >
                      {link.name}
                    </Typography>
                  </Link>
                </Grid>
              ))}
                <Grid item sm={4} my={1} xs={4} gap={1}>
                  <Link to={'https://drive.google.com/file/d/1gCv2OqlS9gFpxWQHpY9b6VtjBifPVlBJ/view?usp=sharing'} target='_blanck'  style={{ textDecoration: "none" }}>
                    {" "}
                    <Typography
                      sx={{ fontSize: "1.35rem", color: "#cbc3c3e3" }}
                    >
                      Tokenomics
                    </Typography>
                  </Link>
                </Grid>

                <Grid item sm={4} my={1} xs={4} gap={1}>
                <Link to={'https://drive.google.com/file/d/1VmnpNVV1M4GXuK9au6aBrsr6UOUrYf99/view?usp=drive_link'} target='_blanck'  style={{ textDecoration: "none" }}>
                  {" "}
                  <Typography
                    sx={{ fontSize: "1.35rem", color: "#cbc3c3e3" }}
                  >
                    Channel Partners 
                  </Typography>
                </Link>
                <Box display={"flex"} flexDirection={"column"} alignItems={{ xs: "start" }} gap={1}>
                    <Box sx={{ mt: "10px" }} width={80}>
                      <Link to='https://www.dexview.com/' target="_blanck" >
                        <img src={dexview} alt="" srcset="" width={"100%"} />
                      </Link>
                    </Box>
                    <Box width={80}>
                      <Link to='https://www.pinksale.finance' target="_blanck" > 
                        <img src={pinksale} alt="" srcset="" width={"100%"} />
                      </Link>
                    </Box>
                </Box>  
              </Grid>

            </Grid>
          </Box>
        </Grid>
        <Grid item md={3} xs={12} mt={2}>
          <Box
            height={"100%"}
            display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography variant="h2" sx={{ fontSize: "1.5rem", mb: 2 }}>
                Follows us to stay updated !
              </Typography>
              <Box display={"flex"} alignItems={{ xs: "center" }} gap={3}>
                <Link
                  to="https://twitter.com/ProPokerNFT"
                  target="_blank"
                  width={30}
                >
                  <img src={twitter} alt="" srcset="" width={"100%"} />
                </Link>
                <Box width={25}>
                  <Link to='https://www.instagram.com/propoker.xyz/' target="_blanck" >
                    <img src={insta} alt="" srcset="" width={"100%"} />
                  </Link>
                </Box>
                <Box width={30}>
                  <Link to='https://t.me/propokerpolygon' target="_blanck" > 
                    <img src={telegram} alt="" srcset="" width={"100%"} />
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;

const footerLinks = [
  {
    name: "About us",
    path: "/aboutUs ",
  },
  // {
  //   name: "Channel Partners",
  //   path: "/",
  // },
  // {
  //   name: "Legality",
  //   path: "/",
  // },
  // {
  //   name: "Responsible Gaming",
  //   path: "/",
  // },
  // {
  //   name: "Payment Partners",
  //   path: "/",
  // },
];

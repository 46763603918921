import { Box, Typography } from "@mui/material";
import React from "react";

const HandsCard = ({ title, image, desc, index }) => {
  return (
    <Box
      sx={{
        gap: 5,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "rgba(255,255,255,0.1)",
        borderRadius: "1rem",
        padding: '1rem',
        height: "100%",
        gap: "1.8rem",
      }}
    >
      <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>
        {index + ". " + title}
      </Typography>
      <Box>
        <img src={image} style={{ width: "100%", height: "100%" }} />
      </Box>
      <Typography  sx={{ fontSize: "1.5rem", fontWeight: "bold" }} >{desc}</Typography>
    </Box>
  );
};

export default HandsCard;

import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const HowToPlayHeader = () => {

  const navigate=useNavigate()
  const secondaryNavArray = [
    // { name: "videos", path: "/howToPlay/videos", id: 1 },
    { name: "Getting Started", path: "/howToPlay/gettingStarted", id: 2 },
    { name: "hands", path: "/howToPlay/hands", id: 3 },
    { name: "actions", path: "/howToPlay/actions", id: 4 },
    { name: "tournaments", path: "/howToPlay/tournaments", id: 5 },
  ];

  const location = useLocation();

  // useEffect(() => {
  //   console.log(location);
  //   let ele=document.getElementById(location.pathname)
  //   console.log(ele)
  // }, [location]);

  function scrollToCenter(e,chip) {
    e.preventDefault()
    console.log(e.target)
   navigate(e.target.id)
    const parent = document.getElementById("parent");
    const chipRect = chip.getBoundingClientRect();
    const parentRect = parent.getBoundingClientRect();
    console.log(parentRect,chipRect)

    // const scrollLeft =
    //   chipRect.left - parentRect.left - (parentRect.width - chipRect.width) / 2;

    // parent.scroll({
    //   left: scrollLeft,
    //   behavior: "smooth",
    // });
  }

  return (
    <Box
      id={"parent"}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "5rem",
        overflow: "scroll",
        px: 2,
        mx: { md: "10rem" },
        scrollbarWidth: "none",
        // Hide the scrollbar for WebKit (Chrome, Safari)
        "&::-webkit-scrollbar": {
          display: "none",
        },
        // scrollSnapType: "x mandatory", // Enable horizontal scroll snap
      }}
    >
      {secondaryNavArray.map((item) => {
        return (
          <NavLink
            key={item.id}
            to={item.path}
            id={item.path}
            className={"secondaryNav"}
            style={({ isActive }) => ({
              backgroundColor: isActive ? "rgba(255,255,255,0.2)" : "",
              color: isActive ? "#FFFFFF" : "#766783",
            })}
            // onClick={(e)=>scrollToCenter(e,e.target)}
          >
            {item.name}
          </NavLink>
        );
      })}
    </Box>
  );
};

export default HowToPlayHeader;

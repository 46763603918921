import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import dummyImg from "../assets/images/coFounder.png";
import manasProfile from "../assets/images/Manas nayak.png";
import sumintProfile from "../assets/images/Sumit singh.png";
import deepProfile from "../assets/images/Deep.png";
import dhruvProfile from "../assets/images/Yash Dev.png";
import ShahnawazProfile from "../assets/images/Shahnawaz.png";
// import aarya from '../assets/images/'
import smithProfile from "../assets/images/Smith Bhojak Profile.png";

const AboutUs = () => {
  return (
    <Box px={"2rem"} my={4}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box
          sx={{
            borderRadius: 2,
            padding: 2,
            width: { md: "75%", sm: "85%" },
            bgcolor: "#ffffff1f",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography variant="text18Head">
            The poker industry is a multi-billion dollar industry that includes
            both live and online poker. The industry has grown significantly in
            recent years, due in part to the rise of online poker
          </Typography>
          <Typography variant="text18Head">
            Live poker is played in casinos and card rooms around the world.
            Online poker is playedon websites and apps that allow players to
            compete against each other.
          </Typography>
          <Typography variant="text18">
            Here are some key facts about the poker industry:
          </Typography>
          <Grid container spacing={2}>
            {keyFactData.map((dt) => (
              <Grid item sm={6} width={"100%"}>
                <Box
                  sx={{
                    bgcolor: "#ffffff1f",
                    borderRadius: 2,
                    py: 3,
                    // minHeight: "90px",
                    px: 1,
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{ fontSize: "1.2rem", textAlign: "center" }}
                  >
                    {dt.desc}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box
          sx={{
            borderRadius: 2,
            padding: 2,
            width: { md: "75%", sm: "85%" },
            bgcolor: "#ffffff1f",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography variant="text24Head">What We Believe In</Typography>
          <Typography variant="text18Head">
            The poker industry is a dynamic and growing industry.
          </Typography>
          <Typography variant="text18">
            Though as professional poker players, we saw some dark side of the
            online poker industry also where a number of challenges, including
            regulation, RNG manipulation, Fund security, and bots participation
            is also happening. However, the industry is also seeing some growth,
            particularly in the online poker market
          </Typography>
          <Grid container spacing={2}>
            {whatWeBeleiveData.map((dt) => (
              <Grid item sm={6} md={4} width={"100%"}>
                <Box
                  sx={{
                    bgcolor: "#ffffff1f",
                    borderRadius: 2,

                    minHeight: "190px",
                    height: "100%",
                    padding: "1rem",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="text18Head" sx={{ textAlign: "center" }}>
                    {dt.heading}
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: "1.2rem",
                      textAlign: "center",
                      mt: 1,
                      color: "#cbc3c3e3",
                    }}
                  >
                    {dt.desc}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box
          sx={{
            borderRadius: 2,
            padding: 2,
            width: { md: "75%", sm: "85%" },
            bgcolor: "#ffffff1f",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography variant="text18">
            Back in 2021, when the Crypto and NFT boom happened, we were also
            one of those who got influenced and started learning about the tech
            behind it. And surprisingly we found out that blockchain tech can
            really resolve the issues of the poker industry which are the
            barriers to user adaptation.
          </Typography>
          <Typography variant="text18">
            With our further assessment results that Web3 can benefit the poker
            community in an umber of ways. Here are some of the specific
            benefits that we can resolve
          </Typography>
          <Grid container spacing={2}>
            {benefitsData.map((dt) => (
              <Grid item xs={12} sm={6} md={3} width={"100%"}>
                <Box
                  sx={{
                    bgcolor: "#ffffff1f",
                    borderRadius: 2,
                    minHeight: "190px",
                    height: "100%",
                    padding: "1rem",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="text18Head" sx={{ textAlign: "center" }}>
                    {dt.heading}
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: "1.2rem",
                      textAlign: "center",
                      mt: 1,
                      color: "#cbc3c3e3",
                    }}
                  >
                    {dt.desc}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box
          sx={{
            borderRadius: 2,
            padding: 2,
            width: { md: "75%", sm: "85%" },
            bgcolor: "#ffffff1f",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography variant="text18">
            The goals for creating a decentralized poker platform are to:
          </Typography>
          <Grid container spacing={2}>
            {decentralizedCards.map((dt) => (
              <Grid
                item
                xs={12}
                md={6}
                width={"100%"}
                minWidth={"200px"}
                justifyContent={"space-between"}
              >
                <Box
                  sx={{
                    bgcolor: "#ffffff1f",
                    borderRadius: 2,
                    py: 2,
                    minHeight: { md: "200px", xs: "150px" },
                    height: "100%",
                    px: 1,
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{ fontSize: "1.5rem", textAlign: "center" }}
                  >
                    {dt.heading}
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: "1.175rem",
                      textAlign: "center",
                      mt: 2,
                      color: "#cbc3c3e3",
                    }}
                  >
                    {dt.desc}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        {/* Co Founders */}
        <Box
          sx={{
            borderRadius: 2,
            padding: 2,
            width: { md: "75%", sm: "85%" },
            bgcolor: "#ffffff1f",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {coFounders.map((data) => (
            <>
              <Typography variant="h3" sx={{ fontSize: "1.75rem" }}>
                {data.heading}
              </Typography>
              <Grid container spacing={2}>
                {data.members.map((dt) => (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    width={"100%"}
                    minWidth={"260px"}
                    justifyContent={"space-between"}
                  >
                    <Box
                      sx={{
                        bgcolor: "#ffffff1f",
                        borderRadius: 2,
                        py: 1,
                        // minHeight: { md: "200px", xs: "150px" },
                        px: 1,
                        width: "100%",
                        display: "flex",
                        gap: 2,
                        height: "100%",
                      }}
                    >
                      <Box sx={{ width: '10rem',display:'grid',placeItems:'center' }}>
                        <img src={dt.img} alt="" srcset="" style={{width:'100%'}} />
                      </Box>
                      <Box py={2}>
                        <Typography variant="h3" sx={{ fontSize: "1.5rem" }}>
                          {dt.name}
                        </Typography>
                        <Typography
                          variant="h3"
                          sx={{
                            fontSize: "1.175rem",
                            // textAlign: "center",
                            mt: 1,
                            color: "#cbc3c3e3",
                          }}
                        >
                          {dt.desig}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUs;

const keyFactData = [
  {
    desc: "The global poker market was worth an estimated $23.75 billion in 2022",
  },
  {
    desc: "The online poker market is the largest segment of the poker industry, accountingfor an estimated $19.5 billion in 2022.",
  },
  {
    desc: `The most popular poker variants are Texas Hold'em, Omaha, and Seven-CardStud.`,
  },
  {
    desc: "The World Series of Poker (WSOP) is the most prestigious poker tournament inthe world which attracts thousands of entrants from worldwide.",
  },
];

const whatWeBeleiveData = [
  {
    heading: "RNG manipulation",
    desc: "Because of Random number generator (RNG), There have been allegations that some centralized online poker platforms have manipulated their RNGs to give themselves an unfair advantage. This has eroded trust among players and made them less likely to participate in online poker games",
  },
  {
    heading: "Fund security",
    desc: "There have been a number of high-profile cases of online pokerplatforms being hacked, resulting in players losing their funds. This has madeplayers understandably wary of depositing their money with online pokerplatforms.",
  },
  {
    heading: "Bot involvement",
    desc: "Bots are software programs that can be used to play poker automatically. Bots can give players an unfair advantage, as they can make decisions much faster than humans and they can never get tired or bored",
  },
];

const benefitsData = [
  {
    heading: "Transparency and trust",
    desc: "With Web3, the game's RNG (random number generator) is transparent and verifiable, which eliminates any concerns about cheating or manipulation. This can help to build trust among players and make them more likely to participate in online poker games.",
  },
  {
    heading: "Security",
    desc: "Web3 uses blockchain technology, which is a secure and tamper-proof way of storing data. This means that players' funds are safe and secure, and there is no risk of them being hacked or stolen.",
  },
  {
    heading: "Bot prevention",
    desc: "Bots are a common problem in online poker, as they can be used to cheat and gain an unfair advantage. Web3 can help to prevent bots from being used by making it more difficult to create and operate them",
  },
  {
    heading: "New features & possibilities",
    desc: "Web3 opens up new possibilities for poker games, such as the ability to create NFT-based poker chips and cards. This could lead to more engaging and immersive poker experiences",
  },
];

const decentralizedCards = [
  {
    heading: "Create a more transparent & fair gaming environment",
    desc: "Decentralized platforms are transparent by design, as all of the game data is stored on the blockchain. This means that players can be confident that the games are fair and that there is no chance of manipulation",
  },
  {
    heading: "Increase security & player funds",
    desc: "Decentralized platforms use blockchain technology to store player funds. This makes it much more difficult for platforms to be hacked, as the blockchain is a secure and tamper-proof way of storing data.",
  },
  {
    heading: "Make poker more accessible to a wider range of people:",
    desc: "Decentralized platforms are not subject to the same regulations as centralized platforms. This means that they can be more accessible to players from all over the world. Additionally, decentralized platforms can be used to create new types of poker games that are more accessible to players of all skill levels.",
  },
  {
    heading: "Create a more engaging & immersive poker experience",
    desc: "Decentralized platforms can use new technologies, such as VR and AR, to create a more engaging and immersive poker experience. This could make poker more attractive to a wider range of players.",
  },
  {
    heading: "Prevent bot involvement",
    desc: "Bots are software programs that can be used to play poker automatically. Bots can give players an unfair advantage, as they can make decisions much faster than humans and they can never get tired or bored. Decentralized platforms can use NFTs to prevent bot involvement. NFTs can be used to create unique and non-transferable tokens that represent poker chips or cards. This makes it much more difficult for bots to be created and operated, as they would need to be able to acquire these tokens in order to play",
  },
];

const coFounders = [
  {
    heading: "Co Founders",
    members: [
      {
        name: "Manas Nayak",
        img: manasProfile,
        desig: "CEO",
      },
      {
        name: "Sumit Singh",
        img: sumintProfile,
        desig: "COO",
      },
      {
        name: "Deep Kharadi",
        img: deepProfile,
        desig: "Product Management",
      },
    ],
  },
  {
    heading: "Tech",
    members: [
      {
        name: "Dhruv Patel",
        img: dhruvProfile,
        desig: "Tech Head",
      },
      {
        name: "Shahnawaz Khan",
        img: ShahnawazProfile,
        desig: "Junior Developer",
      },
    ],
  },
  {
    heading: "Design & Socials",
    members: [
      // {
      //   name: "Aarya Trehan",
      //   img: dummyImg,
      //   desig: "Social Media Manager",
      // },
      {
        name: "Smith Bhojak",
        img: smithProfile,
        desig: "Product Designer",
      },
    ],
  },
];
